import { types } from 'store/actions/sales/product_ins.actions';

const items = [];

const reducer = (state = items, { type, data }) => {
  switch (type) {
  case types.PRODUCT_INS_QUERY: {
    return [...data];
  }
  case types.PRODUCT_INS_CREATE: {
    return [data, ...state];
  }
  case types.PRODUCT_INS_INCREASE:
  case types.PRODUCT_INS_EDIT: {
    return state.map((item) => (item._id === data._id ? { ...data } : item));
  }
  case types.PRODUCT_INS_REMOVE: {
    return state.filter((item) => item._id !== data._id);
  }
  default:
    return state;
  }
};

export default reducer;
