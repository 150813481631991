import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Checkbox } from 'semantic-ui-react';

import api from 'generics/api/globals/v1';
import { GENERIC } from 'generics/constants';
import { toast } from 'generics/services/globals/v4';
import { Flex } from 'components/grids/v2';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { RemoveConfirmation } from 'components/generics/v1';
import { ContentTitle } from 'components';
import { UImage } from 'components/images/v3';
import { IButton } from 'components/buttons/v2';
import { AREA_GROUPS_UPDATE_TITLE } from '../groups.constants';

const AreaGroupUpdate = () => {
  const { group_id } = useParams();
  const history = useHistory();
  const {
    item,
    set_item,
    on_input_change,
    on_image_change,
    on_checkbox_change,
  } = useFormReducer({});

  const on_submit = (e) => {
    e.preventDefault();
    api.groups.update(item)
    .then(() => {
      history.push(`${GENERIC.APP_BASE_URL}/configuration/groups`);
    })
    .catch(toast.api_danger);
  };

  const on_remove_callback = useCallback(() => {
    api.groups.remove(item)
    .then(() => {
      history.push(`${GENERIC.APP_BASE_URL}/configuration/groups`);
    })
    .catch(toast.api_danger);
  }, [history, item]);

  useEffect(() => {
    api.groups.get_by_id(group_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [group_id, set_item]);

  return (
    <>
      <ContentTitle config={AREA_GROUPS_UPDATE_TITLE} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Nombre" value={item.name} onChange={on_input_change} />
        </Form.Field>
        <Form.Field>
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripción" value={item.description} onChange={on_input_change} />
        </Form.Field>
        <Form.Field>
          <Checkbox name="enabled" checked={item.enabled} label="Está habilitado" onChange={on_checkbox_change} />
        </Form.Field>
        <UImage image={item.image_id} height="150px" quality={1080} on_callback={on_image_change} />
        <Flex.End>
          <RemoveConfirmation label="Eliminar grupo" on_click={on_remove_callback} />
          <IButton label="Actualizar grupo" icon="checkmark" submit />
        </Flex.End>
      </Form>
    </>
  );
};

AreaGroupUpdate.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { AreaGroupUpdate };
