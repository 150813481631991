import categories from './categories.actions';
import kiosk from './kiosk.actions';
import product_stocks from './product_stocks.actions';
import toolbar from './toolbar.actions';

export default {
  categories,
  kiosk,
  product_stocks,
  toolbar,
};
