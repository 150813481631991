import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';

import App from './App';
import { store } from './store/index.store';

import 'semantic-ui-css/semantic.min.css';
import 'react-notifications-component/dist/theme.css';
import './index.css';

// window.process = { browser: true, env: { ENVIRONMENT: 'BROWSER' } };

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// const root = createRoot(document.getElementById('root'));

// root.render(
//   <Provider store={store}>
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </Provider>,
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
