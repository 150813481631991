import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from 'store/actions/kiosk';
import { Order } from './Order';
import { IconOrder } from './IconOrder';

import './Orders.scss';

const Orders = () => {
  const { order, orders } = useSelector((state) => state.kiosk);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [items, setItems] = useState(orders);

  const onSelect = useCallback((item) => {
    dispatch(actions.select_order(item));
  }, [dispatch]);

  const onAddSelect = useCallback(() => {
    dispatch(actions.add_order());
  }, [dispatch]);

  const onRemoveSelect = useCallback(() => {
    dispatch(actions.remove_order());
  }, [dispatch]);

  return (
    <div className="cart-orders">
      {
        items.map(
          (item) => (<Order current={order} item={item} onSelect={onSelect} />),
        )
      }
      <IconOrder key="checkmark" icon="checkmark" onSelect={onAddSelect} />
      <IconOrder key="cancel" icon="cancel" onSelect={onRemoveSelect} />
    </div>
  );
};

export { Orders };
