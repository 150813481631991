import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import actions from 'store/actions/kiosk';
import { ApeCardListItem } from './ApeCardListItem';

import './ApeCardList.scss';

const ApeCardList = ({ items }) => {
  const dispatch = useDispatch();

  const onSelect = useCallback((item) => {
    dispatch(actions.kiosk.add_to_cart({
      _id: item.product._id,
      product: item.product,
      product_stock: item.product_stock,
    }));
  }, [dispatch]);

  const convert = (item) => {
    const { product, product_stock } = item;

    if (!product) {
      return {
        image: null,
        title: 'Not found',
        subtitle: 'Not found',
      };
    }
    return {
      image_id: product.image_id,
      title: product.name || 'Not found',
      subtitle: product.description || 'Not found',
      price: product_stock.price,
      amount: product_stock.amount,
    };
  };

  return (
    <div className="ape-kiosk-card-list">
      <div>
        {
          items.map((item) => (
            <ApeCardListItem key={item._id} convert={convert} item={item} onSelect={onSelect} />
          ))
        }
      </div>
    </div>
  );
};

ApeCardList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export { ApeCardList };
