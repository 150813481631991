import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Checkout } from './checkout/Checkout';
import { Invoices } from './invoices/Invoices';

const GRouter = ({ area }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/booking/:booking_id/checkout`}>
        <Checkout area={area} />
      </Route>
      <Route exact path={`${path}/sales/:sale_id/invoices`}>
        <Invoices />
      </Route>
    </Switch>
  );
};

GRouter.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { GRouter };
