import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
// import { QUERY } from 'generics/constants/service.constants';

import { BACKGROUND } from 'generics/constants';
import { Screen } from 'components/screens/v2';
import { Flex } from 'components/grids/v4';
import { Session } from 'app/session/Session';

import { AccountRoutes } from 'app/features/accounts/AccountRoutes';
import { SettingRoutes } from 'app/features/settings/SettingRoutes';
import { BillingRoutes } from 'app/features/billings/BillingRoutes';
import { ConfigurationRoutes } from 'app/features/configurations/ConfigurationRoutes';

import { Header } from '../header/Header';
import { Menu } from '../header/Menu';

import { GRoute as CategoriesRoute } from './categories/GRoute';
import { GRoute as ProductsRoute } from './products/GRoute';
import { GRoute as StoreProductRoute } from './product-stocks/GRoute';
import { GRoute as StoresRoute } from './stores/GRoute';
import { GRoute as SalesPointsRoute } from './sales-points/GRoute';

const Sales = () => {
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);

  const render_sales = () => (
    <Flex.Item>
      <Switch>
        <Route path={`${path}/products`}>
          <ProductsRoute area={area} />
        </Route>
        <Route path={`${path}/stores`}>
          <StoresRoute area={area} />
        </Route>
        <Route path={`${path}/categories`}>
          <CategoriesRoute area={area} />
        </Route>
        <Route path={`${path}/product-stocks`}>
          <StoreProductRoute area={area} />
        </Route>
        <Route path={`${path}/sales-points`}>
          <SalesPointsRoute area={area} />
        </Route>
      </Switch>
      <AccountRoutes />
      <SettingRoutes />
      <BillingRoutes />
      <ConfigurationRoutes />
    </Flex.Item>
  );

  return (
    <Screen background={BACKGROUND}>
      <Session />
      <Menu />
      <Flex.Item>
        <Flex direction={Flex.V}>
          <Header />
          {area && render_sales()}
        </Flex>
      </Flex.Item>
    </Screen>
  );
};

export { Sales };
