import axios from 'axios';
import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';

import apps from './reducers/globals/v1/apps.reducers';
import areas from './reducers/globals/v1/areas.reducers';
import auth from './reducers/globals/v1/auth.reducers';
import groups from './reducers/globals/v1/groups.reducers';
import loading from './reducers/globals/v1/loading.reducers';

import area from './reducers/guests/area.reducers';

import app from './reducers/sales/app.reducers';
import product_ins from './reducers/sales/product_ins.reducers';

import kiosk from './reducers/kiosk/kiosk.reducers';
import toolbar from './reducers/kiosk/toolbar.reducers';
import k_categories from './reducers/kiosk/categories.reducers';
import k_product_stocks from './reducers/kiosk/product_stocks.reducers';

import billings from './reducers/billings/billings.reducers';
// import units from './reducers/units';
// import stock from './reducers/stock';
// import carts from './reducers/carts';
// import stores from './reducers/stores';
// import products from './reducers/products';
// import convertions from './reducers/convertions';
// import checkout from './reducers/checkout.reducers';
// import sales_points from './reducers/sales-points.reducers';
// import organization from './reducers/organization.reducers';

const reducer = combineReducers({
  app,
  apps,
  areas,
  auth,
  groups,
  loading,

  area,
  product_ins,

  toolbar,
  kiosk,
  k_categories,
  k_product_stocks,

  billings,

  // convertions,
  // stores,
  // products,
  // checkout,
  // units,
  // stock,
  // sales_points,
  // carts,
  // organization,
});

const store = createStore(
  reducer,
  applyMiddleware(thunk.withExtraArgument(axios)),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export { store };
