import axios from 'axios';

import { BASE_PATH } from 'generics/constants';
import { AuthService } from './auth.service';

const PATH = `${BASE_PATH.APP}/v1/stores`;

const query = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).get(PATH, { params });
};

const get_by_id = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).get(`${PATH}/${params._id}`);
};

const create = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).post(PATH, params);
};

const edit = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).put(`${PATH}/${params._id}`, params);
};

const remove = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).delete(`${PATH}/${params._id}`);
};

export { query, get_by_id, create, remove, edit };
