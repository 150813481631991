import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from 'store/actions/billings';
import kiosk_actions from 'store/actions/kiosk';
import { CheckoutCustomerModal } from './CheckoutCustomerModal';

const SearchCurstomer = () => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.kiosk);
  const { customer } = useSelector((state) => state.billings);
  const [open, set_open] = useState(false);
  // eslint-disable-next-line
  const [visitors, set_visitors] = useState([]);

  const on_select_customer = useCallback((item) => {
    dispatch(actions.billings.store_customer(item));
    dispatch(kiosk_actions.kiosk.open_client_dialog({ is_open: false }));
  }, [dispatch]);

  useEffect(() => {
    set_open(client.is_open);
  }, [client]);

  return (
    <CheckoutCustomerModal
      open={open}
      set_open={set_open}
      customer={customer}
      visitors={visitors}
      set_customer={on_select_customer}
    />
  );
};

export { SearchCurstomer };
