import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { GENERIC } from 'generics/constants';
import { Button, HFlex, VContainer, VFlex } from 'components';
import { ToolbarList } from './toolbar/ToolbarList';
import { GroupCreateModal } from './modals/GroupCreateModal';

import './Menu.scss';

const Menu = () => {
  const { account } = useSelector((state) => state.auth);
  const { area } = useSelector((state) => state.apps);
  const { areas } = useSelector((state) => state.areas);
  const [drawer, set_drawer] = useState(true);
  const [size, set_size] = useState(250);
  const [open, set_open] = useState(false);
  const [sessionLinks] = useState([
    { icon: 'shop', label: 'Punto de venta', uri: '/market/kiosk' },
  ]);
  const [options] = useState([
    { icon: 'home', label: 'Inicio', uri: GENERIC.APP_BASE_URL },

    { icon: 'boxes', label: 'Inventario', uri: `${GENERIC.APP_BASE_URL}/product-stocks` },
    { icon: 'shopping cart', label: 'Productos', uri: `${GENERIC.APP_BASE_URL}/products` },
    { icon: 'shopping basket', label: 'Punto de ventas', uri: `${GENERIC.APP_BASE_URL}/sales-points` },
    { icon: 'marker', label: 'Almacen', uri: `${GENERIC.APP_BASE_URL}/stores` },
    { icon: 'tags', label: 'Categorias', uri: `${GENERIC.APP_BASE_URL}/categories` },
  ]);

  const [sale_options] = useState([
    { icon: 'box', label: 'Checkout', uri: `${GENERIC.APP_BASE_URL}/checkout` },
    { icon: 'long arrow alternate up', label: 'Ventas', uri: `${GENERIC.APP_BASE_URL}/sales` },
    { icon: 'long arrow alternate down', label: 'Compras', uri: `${GENERIC.APP_BASE_URL}/buys` },
    { icon: 'percent', label: 'Facturas', uri: `${GENERIC.APP_BASE_URL}/invoices` },
    { icon: 'boxes', label: 'Caja diaria', uri: `${GENERIC.APP_BASE_URL}/terminates` },
    { icon: 'setting', label: 'Ajustes', uri: `${GENERIC.APP_BASE_URL}/settings/detail` },
    { icon: 'setting', label: 'Administración', uri: `${GENERIC.APP_BASE_URL}/configuration/detail` },
  ]);

  const handle_drawer = (e) => {
    e.stopPropagation();
    set_drawer(!drawer);
  };

  const render_area = (organization) => {
    let organization_label = null;
    if (drawer) {
      organization_label = <h3 className="administrator-title">{organization.name}</h3>;
    } else {
      organization_label = <h3 className="administrator-title-small">{organization.name[0]}</h3>;
    }

    return (
      <VFlex>
        {organization_label}
      </VFlex>
    );
  };

  const render_create_organization = () => (
    <div className="administrator-create-content">
      <Button label="Crear organización" icon="checkmark" onClick={() => set_open(true)} />
      <GroupCreateModal open={open} set_open={set_open} />
    </div>
  );

  useEffect(() => {
    if (drawer) {
      set_size(250);
    } else {
      set_size(50);
    }
  }, [drawer]);

  return (
    <HFlex size={size} only={['mobile', 'computer', 'screen']} color="#1616163b">
      <VContainer>
        <HFlex>
          <div aria-hidden="true" className="administrator-drawer-icon" onClick={handle_drawer}>
            <Icon name="list" size="big" />
          </div>
        </HFlex>
        {account && <ToolbarList links={sessionLinks} select={sessionLinks[1]} />}
        {(account && !areas.length) && render_create_organization()}
        {area && render_area(area)}
        {area && <ToolbarList label={drawer} links={options} />}
        <h3 className="administrator-title">Administración</h3>
        {area && <ToolbarList label={drawer} links={sale_options} />}
      </VContainer>
    </HFlex>
  );
};

export { Menu };
