import React from 'react';
import { useSelector } from 'react-redux';

import './CartSummaryTitle.scss';

const CartSummaryTitle = () => {
  const { customer } = useSelector((state) => state.billings);

  return (
    <div className="cart-summary-title">
      <h1>
        { customer ? `${customer.customer_name} (${customer.customer_index})` : 'Sin cliente' }
      </h1>
    </div>
  );
};

export { CartSummaryTitle };
