import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import actions from 'store/actions/globals/v1';

import { Button } from 'components';
import { HToolbarButton, HToolbarTitle, HToolbarFlex } from '../container/HToolbarContainer';
import image from '../../../assets/images/hardcode/male_user.jpeg';

import './HeaderLogin.scss';

const HeaderLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { account } = useSelector((state) => state.auth);

  const on_click = () => {
    history.push('/sales/accounts/detail');
  };

  const on_logout = () => {
    history.push('/');
    dispatch(actions.auth.logout());
  };

  return (
    <>
      <HToolbarTitle />
      <HToolbarFlex />
      <HToolbarButton>
        <div className="login-information" onClick={on_click}>
          <div>
            <img src={account.image || image} alt={account.name} />
          </div>
          <div>
            <h4>{account.name || account.email}</h4>
          </div>
        </div>
      </HToolbarButton>
      <HToolbarButton>
        <Button label="Cerrar session" icon="checkmark" onClick={on_logout} />
      </HToolbarButton>
    </>
  );
};

export { HeaderLogin };
