import React from 'react';
import QRCode from 'react-qr-code';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Image } from 'components/images/v3';

import { CheckoutInvoiceCart } from './CheckoutInvoiceCart';

const LetterInvoice = ({ invoice }) => {
  const render_headquarter = (headquarter) => {
    if (!headquarter || headquarter === '') {
      return 'CASA MATRIZ';
    }

    return `CASA MATRIZ - ${headquarter}`;
  };

  return (
    <div id="checkout-invoice" style={{ width: '770px', background: '#ffffff', fontFamily: 'Verdana, Sans-serif', fontSize: '11px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'auto', color: '#000000', padding: '10px', lineHeight: 1 }}>
        <div style={{ flex: 4 }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <Image image={invoice.image_id} direction={Image.H} width="80px" quality={80} />
              </div>
              <div style={{ flex: 4 }}>
                <h3 style={{ margin: '0px' }}>
                  {invoice.area_name}
                </h3>
                <div>
                  {`De: ${invoice.area_owner}`}
                </div>
                <div>
                  {render_headquarter(invoice.area_headquarter)}
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div>
              {invoice.area_address}
            </div>
            <div>
              {invoice.area_phone}
            </div>
            <div>
              {invoice.area_city}
            </div>
          </div>
        </div>
        <div style={{ flex: 3 }}>
          <div style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold' }}>
            FACTURA
          </div>
        </div>
        <div style={{ flex: 4 }}>
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            <div>
              {`NIT: ${invoice.area_nit}`}
            </div>
            <h5 style={{ margin: '0px' }}>
              {`FACTURA Nro: ${invoice.invoice_counter}`}
            </h5>
            <div>
              {`AUTORIZACIÓN Nro: ${invoice.invoice_authorization_number}`}
            </div>
            <div>
              {`Actividad Económica: ${invoice.area_activity}`}
            </div>
            <div style={{ fontWeight: 'bold' }}>
              FACTURA ORIGINAL
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: '#ffffff', overflowY: 'auto', color: '#000000', padding: '0px 10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <div>
              {`FECHA: ${moment(invoice.created).format('DD/MM/YYYY h:mm A')}`}
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div>
              {`NIT/CI: ${invoice.customer_index}`}
            </div>
          </div>
        </div>
        <div>
          {`SEÑOR(ES): ${invoice.customer_name}`}
        </div>
        <CheckoutInvoiceCart sale={invoice} />
        <div>
          {`SON: ${invoice.total_literal}`}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'auto', color: '#000000', padding: '0px 10px' }}>
        <div style={{ flex: 8 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>
              {`CODIGO CONTROL: ${invoice.control_code_text}`}
            </div>
            <div style={{ flex: 1 }}>
              {`FECHA LIMITE DE EMISION: ${moment(invoice.invoice_emission_deadline).format('DD/MM/YYYY')}`}
            </div>
          </div>
          <div style={{ fontSize: '10px' }}>
            <div style={{ textAlign: 'center' }}>
              &quot;ESTA FACTURA CONTRIBUYE AL DESARROLLO DEL PAÍS.
              EL USO ILÍCITO DE ÉSTA SERÁ SANCIONADO DE ACUERDO A LEY&quot;
            </div>
            <div style={{ textAlign: 'center' }}>
              {`${invoice.invoice_consumer_right}`}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 2, fontSize: '10px' }}>
              {`Emitido por: ${invoice.account_name}`}
            </div>
            <div style={{ flex: 2, fontSize: '8px', textAlign: 'right' }}>
              visitors.wargos-solutions.com
            </div>
          </div>
        </div>
        <div style={{ flex: 2 }}>
          <div>
            <div style={{ textAlign: 'center' }}>
              <QRCode
                size={120}
                value={invoice.qr_text}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LetterInvoice.propTypes = {
  invoice: PropTypes.any.isRequired,
};

export { LetterInvoice };
