import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { GItem } from './GItem';
import { GItemCam } from './GItemCam';
import { GItems } from './GItems';

import { URI, ROUTE } from './constants';

const GRoute = ({ area }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <GItems route={ROUTE} uri={URI} area={area} />
      </Route>
      <Route exact path={`${path}/new`}>
        <GItem route={ROUTE} uri={URI} area={area} />
      </Route>
      <Route exact path={`${path}/newcam`}>
        <GItemCam route={ROUTE} uri={URI} area={area} />
      </Route>
      <Route exact path={`${path}/:_id`}>
        <GItem route={ROUTE} uri={URI} area={area} />
      </Route>
    </Switch>
  );
};

GRoute.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { GRoute };
