import React from 'react';

import { VContainer } from '../../components';

// #254f6d
const Explore = () => (
  <VContainer>
    Explorar
  </VContainer>
);

export { Explore };
