import React from 'react';

import { CartList } from './CartList';
import { Orders } from './orders/Orders';
import { PayActions } from './pay/PayActions';
import { CartSummaryTitle } from './CartSummaryTitle';
import { SearchCurstomer } from '../billings/client/SearchCurstomer';
import { VContainer, VContainerContent } from '../../../container/VContainer';
import { HContainer, HContainerContent } from '../../../container/HContainer';

import './CartItem.scss';

const KioskCart = () => (
  <VContainer>
    <SearchCurstomer />
    <CartSummaryTitle />
    <VContainerContent>
      <HContainer>
        <Orders />
        <HContainerContent>
          <CartList />
        </HContainerContent>
      </HContainer>
    </VContainerContent>
    <PayActions />
  </VContainer>
);

export { KioskCart };
