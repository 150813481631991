import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Flex } from 'components/grids/v2';
import actions from 'store/actions/kiosk/';
import { api, toast } from 'generics/services/globals/v3';
import { Search } from 'components/elements/v1';

import { Chips } from './chips/Chips';
import { ApeCardList } from './list/ApeCardList';

const KioskProducts = ({ area }) => {
  const { sales_point } = useSelector((state) => state.kiosk);
  const { product_stocks } = useSelector((state) => state.k_product_stocks);
  const { categories } = useSelector((state) => state.k_categories);

  const [currentStocks, set_current_stocks] = useState(product_stocks);
  const [currentCategories, set_current_categories] = useState(categories);
  const [category, setCategory] = useState(null);
  const dispatch = useDispatch();
  const [search, set_search] = useState('');

  const onHandleSelectCategory = useCallback((item) => {
    if (item._id) {
      setCategory(item);
    } else {
      setCategory(null);
    }
  }, []);

  const filter_items = useCallback(() => currentStocks.reduce((resicle, item) => {
    if (!item.product) {
      return resicle;
    }
    if ((item.product.name || '').toLowerCase().match(new RegExp(search))) {
      resicle.push(item);
    }
    return resicle;
  }, []), [currentStocks, search]);

  useEffect(() => {
    if (category) {
      const items = product_stocks.filter((item) => (
        _.includes(item.product.categories, category._id)
      ));
      set_current_stocks([...items]);
    } else {
      set_current_stocks([...product_stocks]);
    }
  }, [category, product_stocks]);

  useEffect(() => {
    set_current_categories([...categories]);
  }, [categories]);

  useEffect(() => {
    api.get('/v2/product-stocks', null, { area_id: area._id, store_id: sales_point.store_id })
    .then(({ data }) => {
      dispatch(actions.product_stocks.set(data));
    })
    .catch(toast.api_danger);
    api.get('/v1/categories', null, { area_id: area._id })
    .then(({ data }) => {
      dispatch(actions.categories.set(data));
    })
    .catch(toast.api_danger);
  }, [dispatch, area, sales_point]);

  return (
    <>
      <Flex.Scrap>
        <Flex direction={Flex.H}>
          <Flex.Scrap>
            <Chips
              items={currentCategories}
              current={category}
              onHandleClick={onHandleSelectCategory}
            />
          </Flex.Scrap>
          <Flex.Item />
          <Flex.Scrap>
            <Search search={search} set_search={set_search} />
          </Flex.Scrap>
        </Flex>
      </Flex.Scrap>
      <ApeCardList items={filter_items(currentStocks)} />
    </>
  );
};

KioskProducts.propTypes = {
  area: PropTypes.any.isRequired,
};

export { KioskProducts };
