import product_ins from './product-ins.service';
import product_stocks from './product-stocks.service';
import sales_points from './sales-points.service';
import stores from './stores.service';

export default {
  product_ins,
  product_stocks,
  sales_points,
  stores,
};
