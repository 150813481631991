import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import Webcam from 'react-webcam';
import { files } from 'generics/services/globals';

import { api, toast } from 'generics/services/globals/v3';
import { MultiSelect as V2MultiSelect } from 'components/forms/v2';
import { ContentTitle } from 'components/titles/v2';
import { Flex } from 'components/grids/v2';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { RemoveConfirmation } from 'components/generics/v1';
import { IButton } from 'components/buttons/v2';
import service from 'generics/api/sales/v2';
import { loader } from 'generics/services/globals/v4';
import { DEFAULT_PRODUCT_STOCK } from './product.constants';

import { ITEM_HEADER, BUTTON_LABELS } from './constants';

const videoConstraints = {
  width: 512,
  height: 512,
  facingMode: 'environment',
};

navigator.permissions.query({ name: 'camera' })
.then((permissionObj) => {
  console.info(permissionObj.state);
})
.catch((error) => {
  console.error('Got error :', error);
});

const GItemCam = ({ route, uri, area }) => {
  const { _id } = useParams();
  const history = useHistory();
  const [stores, set_stores] = useState([]);
  const [product_stocks, set_product_stocks] = useState([]);
  const [is_new] = useState(_id === undefined);
  const {
    item,
    set_item,
    on_string_change,
    on_image_change,
  } = useFormReducer({
    area_id: area._id,
    name: '',
    description: '',
    categories: [],
  });
  const webcamRef = React.useRef(null);

  const create = useCallback(() => {
    if (item.name === '') {
      toast.danger('Ingresar un nombre');
      return;
    }

    const image_src = webcamRef.current.getScreenshot();

    loader.resolve()
    .then(loader.start)
    .then(() => files.upload_string_images(image_src, (chuck) => {
      /* eslint-disable no-console */
      console.log('uploading..', Math.round((100 * chuck.loaded) / chuck.total));
    }))
    .then(({ data }) => {
      console.log(data);
      // set_item({ ...item, image_id: data._id });

      return api.post(uri.post, null, { ...item, image_id: data._id });
    })
    .then(({ data }) => {
      const aux_stocks = stores.map((store) => ({
        product_id: data._id,
        area_id: data.area_id,
        store_id: store._id,
        ...DEFAULT_PRODUCT_STOCK,
      }));

      return Promise.all(aux_stocks.map((store) => (
        service.product_stocks.create(store)
      )));
    })
    .then(() => {
      history.push(route.list);
    })
    .then(loader.stop)
    .catch(toast.api_danger);
  }, [webcamRef, set_item, item, route, history, stores]);

  const update = () => {
    api.put(uri.put, { _id }, item)
    .then(() => {
      history.push(route.list);
    })
    .catch(toast.api_danger);
  };

  const on_submit = (e) => {
    e.preventDefault();
    if (is_new) {
      create();
    } else {
      update();
    }
  };

  const on_remove_callback = useCallback(() => {
    api.remove(uri.remove, { _id }, item)
    .then(() => {
      history.push(route.list);
    })
    .catch(toast.api_danger);
  }, [uri, _id, history, route, item]);

  const on_categories_change = (_ids) => {
    set_item({ ...item, categories: _ids });
  };

  useEffect(() => {
    api.get('/v1/stores', null, { area_id: area._id })
    .then(({ data }) => {
      set_stores(data);

      if (!is_new) {
        service.product_stocks.find({ area_id: area._id, product_id: _id })
        .then(({ data: aux_stocks }) => {
          const current_stocks = aux_stocks.map(({ product_stock }) => {
            const store = data.find((s) => s._id === product_stock.store_id);
            if (!store) {
              // eslint-disable-next-line
              console.error('not fund store');
              return null;
            }

            return {
              value: store._id,
              label: store.name,
            };
          });

          set_product_stocks(current_stocks);
        })
        .catch(toast.api_danger);
      }
    })
    .catch(toast.api_danger);
  }, [area, is_new, _id]);

  useEffect(() => {
    if (is_new) return;

    api.get(uri.get_by_id, { _id }, null)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [is_new, _id, uri, area, set_item]);

  const render_buttons = () => {
    if (is_new) {
      return <IButton label={BUTTON_LABELS.CREATE} icon="checkmark" submit />;
    }

    return (
      <>
        <RemoveConfirmation label={BUTTON_LABELS.REMOVE} on_click={on_remove_callback} />
        <IButton label={BUTTON_LABELS.UPDATE} icon="checkmark" submit />
      </>
    );
  };

  return (
    <>
      <ContentTitle config={ITEM_HEADER} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Nombre" value={item.name} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <V2MultiSelect selector="name" placeholder="Categoria" path="/v1/categories" params={{ area_id: area._id }} _ids={item.categories} on_select={on_categories_change}>
            <label>Categoria</label>
          </V2MultiSelect>
        </Form.Field>
        <Webcam
          audio={false}
          height="auto"
          width="100%"
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
        <Flex.End>
          {render_buttons()}
        </Flex.End>
        {/* <button type="button" onClick={capture_image}>Guardar Foto</button> */}
      </Form>
    </>
  );
};

GItemCam.propTypes = {
  route: PropTypes.any.isRequired,
  uri: PropTypes.shape({
    get: PropTypes.string.isRequired,
    get_by_id: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    put: PropTypes.string.isRequired,
    remove: PropTypes.string.isRequired,
  }).isRequired,
  area: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export { GItemCam };
