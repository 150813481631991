import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'semantic-ui-react';

import './CartItem.scss';

const CartItem = ({ current, item, onSelect, onCancel }) => {
  const cart = item.sale;
  const { product } = item;

  const handleSelect = (e) => {
    e.stopPropagation();
    // e.preventDefault();
    onSelect(item);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    // e.preventDefault();
    onCancel(item);
  };

  return (
    <div className={classnames('cart-item', { 'cart-item-select': current && current._id === item._id })} onClick={handleSelect}>
      <div className="cart-item-container">
        <div className="cart-item-name">{product.name}</div>
        <div className="cart-item-detail">
          {`${cart.amount} U a ${cart.price_sale} (${cart.price_original}) Bs.`}
        </div>
      </div>
      <div className="cart-item-total">
        <div>{`${cart.amount} x ${cart.price_sale} Bs.`}</div>
        <div>{`${cart.amount * cart.price_sale} Bs.`}</div>
      </div>
      <div className="cart-item-icon" onClick={handleCancel}>
        <Icon name="cancel" size="large" />
      </div>
    </div>
  );
};

CartItem.propTypes = {
  current: PropTypes.any,
  item: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

CartItem.defaultProps = {
  current: null,
};

export { CartItem };
