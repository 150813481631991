import { types } from 'store/actions/sales/app.actions';

const initialState = {
  search: '',
  store: null,
};

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
  case types.STORE_SEARCH: {
    return { ...state, search: data };
  }
  case types.STORE_STORE: {
    return { ...state, store: data };
  }
  default:
    return state;
  }
};

export default reducer;
