import React, { useCallback, useEffect, useState } from 'react';
import { Form, Checkbox, Select } from 'semantic-ui-react';
import { useParams, useHistory } from 'react-router-dom';

import api from 'generics/api/globals/v1';
import { GENERIC } from 'generics/constants';
import { toast } from 'generics/services/globals/v4';
import { generic } from 'generics/functions/globals';
import { RemoveConfirmation } from 'components/generics/v1';
import { Flex } from 'components/grids/v2';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { IButton, ContentTitle } from 'components';
import { UImage } from 'components/images/v3';
import { AREA_UPDATE_TITLE } from '../areas.constants';

const AreaUpdate = () => {
  const { area_id } = useParams();
  const history = useHistory();
  const {
    item,
    set_item,
    on_input_change,
    on_image_change,
    on_checkbox_change,
  } = useFormReducer({});
  const [groups, set_groups] = useState([]);

  const on_submit = (e) => {
    e.preventDefault();
    api.areas.update(item)
    .then(() => {
      history.push(`${GENERIC.APP_BASE_URL}/configuration/areas`);
    })
    .catch(toast.api_danger);
  };

  const on_group_change = (event, element) => {
    set_item({ ...item, group_id: element.value });
  };

  const on_remove_callback = useCallback(() => {
    api.areas.remove(item)
    .then(() => {
      history.push(`${GENERIC.APP_BASE_URL}/configuration/areas`);
    })
    .catch(toast.api_danger);
  }, [history, item]);

  useEffect(() => {
    api.areas.get_by_id(area_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);

    api.groups.find({})
    .then(({ data }) => {
      set_groups(data);
    })
    .catch(toast.api_danger);
  }, [area_id, set_item]);

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Nombre" value={item.name} onChange={on_input_change} />
        </Form.Field>
        <Form.Field>
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripción" value={item.description} onChange={on_input_change} />
        </Form.Field>
        <Form.Field>
          <Checkbox name="enabled" checked={item.enabled} label="Está habilitado" onChange={on_checkbox_change} />
        </Form.Field>
        <Form.Field>
          <label>Grupo</label>
          <Select placeholder="Grupo" value={item.group_id} onChange={on_group_change} options={generic.get_options(groups, 'name')} />
        </Form.Field>
        <UImage image={item.image_id} height="150px" quality={1080} on_callback={on_image_change} />
        <Flex.End>
          <RemoveConfirmation label="Eliminar area" on_click={on_remove_callback} />
          <IButton label="Actualizar area" icon="checkmark" submit />
        </Flex.End>
      </Form>
    </>
  );
};

export { AreaUpdate };
