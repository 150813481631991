import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import api from 'generics/api/globals/v1';
import { GENERIC } from 'generics/constants';
import { toast } from 'generics/services/globals/v4';
import { FormLabels } from 'components/forms/v1';
import { Image } from 'components/images/v3';
import { ModelTitle } from 'components/titles/v2';
import { IButton } from 'components/buttons/v2';
import { Flex } from 'components/grids/v2';
import { CONFIG_CONTEXT } from '../configuration.constants';

import { SearchAdministrators } from '../administrators/SearchAdministrators';

const AreaDetail = () => {
  const { area_id } = useParams();
  const history = useHistory();
  const [item, set_item] = useState({
    accounts: [],
  });

  const on_update = () => {
    history.push(`${GENERIC.APP_BASE_URL}/configuration/areas/${item._id}/update`);
  };

  const update_accounts = useCallback((items) => {
    api.areas.update({
      _id: item._id,
      accounts: items,
    })
    .catch(toast.api_danger);
  }, [item]);

  useEffect(() => {
    api.areas.get_by_id(area_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [area_id, set_item]);

  return (
    <FormLabels>
      <ModelTitle context={CONFIG_CONTEXT} title={item.name} description={item.description} />
      <Flex.End>
        <IButton label="Actualizar area" icon="edit" on_click={on_update} />
      </Flex.End>
      {/* <FormLabels.Field>
        <FormLabels.Label value="Nombre" />
        <FormLabels.Input value={item.name} />
      </FormLabels.Field>
      <FormLabels.Field>
        <FormLabels.Label value="Descripción" />
        <FormLabels.Input value={item.description} />
      </FormLabels.Field> */}
      <Image image={item.image_id} direction={Image.H} height="150px" quality={1080} />
      <SearchAdministrators item={item} on_update={update_accounts} />
    </FormLabels>
  );
};

export { AreaDetail };
