export const types = {
  STORE_CUSTOMER: 'store-customer',
};

const store_customer = (data) => ({
  type: types.STORE_CUSTOMER,
  data,
});

export default {
  store_customer,
};
