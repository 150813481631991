import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Flex } from 'components/grids/v2';
import api from 'generics/api/billings/v1';
import { toast } from 'generics/services/globals/v4';
import actions from 'store/actions/kiosk';
import billing_actions from 'store/actions/billings';

import { CheckoutConfig } from './CheckoutConfig';
import { CheckoutSummary } from './CheckoutSummary';
import { CartSummaryTitle } from './CartSummaryTitle';
import { calc_products_v2 } from '../product_builder';
import { CheckoutCustomerModal } from '../client/CheckoutCustomerModal';

const Checkout = ({ area }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.auth);
  // const { rooms, room_types } = useSelector((state) => state.main);
  const { booking_id } = useParams();
  const [open, set_open] = useState(false);
  const [config, set_config] = useState(null);
  const [booking, set_booking] = useState(null);
  const [products, set_products] = useState(null);
  const [customer, set_customer] = useState(null);
  const [visitors, set_visitors] = useState([]);
  const [payment, set_payment] = useState({
    total: 0,
    cash: 0,
    refund: 0,
  });

  const on_payment = useCallback(() => {
    if (!customer) {
      set_open(true);
      return;
    }

    const new_item = {
      _id: booking_id,
      area_id: area._id,
      active: false,
      area_type: 'kiosk',
      coin_type: config.coin_type,
      payment_type: config.payment_type,
      order_type: config.order_type,
      customer,
      room_ids: booking.room_ids,
      visitors: booking.visitors,
      account_id: account._id,
      account,
      area,
      products,
      description: products.reduce((o, p) => {
        o.push(`${p.name} (${p.amount})`);
        return o;
      }, []).join(', '),
      payment,
      completed: moment(),
      closed: false,
    };

    api.sales.create(new_item)
    .then(({ data }) => {
      // reset order
      dispatch(billing_actions.billings.store_customer(null));
      dispatch(actions.kiosk.clean_cart());

      history.push(`/market/sales/sales/${data._id}/invoices`);
      // history.push(`${GENERIC.APP_BASE_URL}/main/sales/${data._id}/invoices`);
      // history.push(`/market/sales/booking/${item._id}/checkout`);
    })
    .catch(toast.api_danger);
  }, [history, dispatch, area, account, booking, customer, payment, products, config, booking_id]);

  const on_customer = useCallback(() => {
    set_open(true);
  }, []);

  const on_select_customer = useCallback((item) => {
    api.bookings.update({
      _id: booking_id,
      customer: item,
    })
    .then(({ data }) => {
      set_customer(data.customer);
    })
    .catch(toast.api_danger);
  }, [booking_id]);

  useEffect(() => {
    api.bookings.get_by_id(booking_id)
    .then(({ data }) => {
      set_booking(data);
      set_customer(data.customer);
      set_visitors(data.visitors);
      set_config({
        coin_type: data.coin_type,
        payment_type: data.payment_type,
        order_type: data.order_type,
      });

      const summary_rooms = calc_products_v2(
        data.room_ids,
        data.otros,
        //calc_days(data),
        [], // rooms,
        [], // room_types,
        data.visitors,
      );
      const extras = data.products.map((product) => ({
        type: 'otros',
        name: product.name,
        amount: product.amount,
        price: product.price,
      }));
      const products_list = summary_rooms.concat(extras);
      const total = products_list.reduce((o, p) => o + (p.price * p.amount), 0);

      set_products([...products_list]);
      set_payment({
        total,
        cash: total,
        refund: 0,
      });
    })
    .catch(toast.api_danger);
  // }, [rooms, room_types, booking_id]);
  }, [booking_id]);

  return (
    <Flex direction={Flex.H}>
      {config && <CheckoutConfig config={config} set_config={set_config} />}
      <Flex.Item>
        {customer && <CartSummaryTitle customer={customer} />}
        <CheckoutSummary
          payment={payment}
          set_payment={set_payment}
          on_payment={on_payment}
          on_customer={on_customer}
        />
        <div>
          <CheckoutCustomerModal
            open={open}
            set_open={set_open}
            customer={customer}
            visitors={visitors}
            set_customer={on_select_customer}
          />
        </div>
      </Flex.Item>
    </Flex>
  );
};

Checkout.propTypes = {
  area: PropTypes.any.isRequired,
};

export { Checkout };
