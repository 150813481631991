import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/images/v3';

import './ApeCardList.scss';

const ApeCardListItem = ({ convert, item, onSelect }) => {
  const [data, setData] = useState(convert(item));

  const handleSelect = (e) => {
    e.stopPropagation();
    onSelect(item);
  };

  useEffect(() => {
    setData(convert(item));
  }, [convert, item]);

  return (
    <div className="list-item" onClick={handleSelect}>
      <div className="options">
        {/* <div className="icons">
          <Icon name='like' size='large' />
          <Icon name='like outline' size='large' />
        </div> */}
        {/* <div className="price">
          {data.price}
          {data.amount}
        </div> */}
      </div>
      <div className="image">
        <Image image={data.image_id} direction={Image.H} height="100%" width="100%" quality={128} />
      </div>
      <div className="title">{`${data.price}Bs`}</div>
      <div className="subtitle">{data.title}</div>
    </div>
  );
};

ApeCardListItem.propTypes = {
  convert: PropTypes.func.isRequired,
  item: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export { ApeCardListItem };
