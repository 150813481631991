import React from 'react';
import PropTypes from 'prop-types';

import './Order.scss';

const Order = ({ current, item, onSelect }) => {
  const handleItemSelect = (e) => {
    e.stopPropagation();
    onSelect(item);
  };

  return (
    <div className={`cart-order ${current.key === item.key && 'cart-order-selected'}`} onClick={(e) => handleItemSelect(e)}>
      {item.value}
    </div>
  );
};

Order.propTypes = {
  item: PropTypes.any.isRequired,
  current: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export { Order };
