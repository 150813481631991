import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '../../grids/v4';

import './Search.scss';

const Search = ({ search, set_search }) => {
  const on_search = useCallback((event) => {
    set_search(event.target.value);
  }, [set_search]);

  return (
    <Flex.Item>
      <div className="ui search">
        <input
          className="prompt"
          type="text"
          placeholder="Nombre del producto"
          value={search}
          onChange={on_search}
        />
        <div className="results" />
      </div>
    </Flex.Item>
  );
};

Search.propTypes = {
  search: PropTypes.string.isRequired,
  set_search: PropTypes.any.isRequired,
};

export { Search };
