export const types = {
  PRODUCT_INS_INCREASE: 'product-ins-increase',
  PRODUCT_INS_SEARCH: 'product-ins-search',
  PRODUCT_INS_QUERY: 'product-ins-query',
  PRODUCT_INS_CREATE: 'product-ins-create',
  PRODUCT_INS_EDIT: 'product-ins-edit',
  PRODUCT_INS_REMOVE: 'product-ins-remove',
};

const increase = (data) => ({
  type: types.PRODUCT_INS_INCREASE,
  data,
});

const search = (data) => ({
  type: types.PRODUCT_INS_SEARCH,
  data,
});

const query = (data) => ({
  type: types.PRODUCT_INS_QUERY,
  data,
});

const create = (data) => ({
  type: types.PRODUCT_INS_CREATE,
  data,
});

const edit = (data) => ({
  type: types.PRODUCT_INS_EDIT,
  data,
});

const remove = (data) => ({
  type: types.PRODUCT_INS_REMOVE,
  data,
});

export default { increase, search, query, create, edit, remove };
