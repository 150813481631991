import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import actions from 'store/actions/kiosk/toolbar.actions';

import './Menu.scss';

const Menu = () => {
  const { point } = useSelector((state) => state.toolbar);
  const dispatch = useDispatch();
  const history = useHistory();

  const [links, setLinks] = useState([
    { _id: '1', icon: 'shop', label: 'Punto', uri: '/market/kiosk', isActive: true },
    // { _id: '2', icon: 'cart arrow down', label: 'Punto', uri: '/market/checkout', isActive: true },
    // { _id: '3', icon: 'history', label: 'Ventas', uri: '/market/history', isActive: true },
    { _id: '4', icon: 'setting', label: 'Config', uri: '/market/configuration', isActive: false },
    { _id: '5', icon: 'home', label: 'Inicio', uri: '/sales', isActive: false },
  ]);

  const onHandleRoute = (item) => {
    links.forEach((link) => {
      if (link.uri === item.uri) {
        link.isActive = true;
      } else {
        link.isActive = false;
      }
    });
    setLinks([...links]);
    dispatch(actions.setToolbar(item));
    history.push(item.uri);
  };

  return (
    <div className="point-list">
      {
        links.map((link) => (
          <div key={link._id} className={point && link.uri === point.uri ? 'point-list-item active' : 'point-list-item'} onClick={() => onHandleRoute(link)}>
            <List.Icon name={link.icon} size="big" />
            <div>{link.label}</div>
          </div>
        ))
      }
    </div>
  );
};

export { Menu };
