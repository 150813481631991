import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { loader } from 'generics/services/globals/v2';
import api from 'generics/api/billings/v1';
import { toast, StorageService } from 'generics/services/globals';
import actions from 'store/actions/kiosk';
import { SalesButton } from '../../../../components/button/SalesButton';

import { ACTIONS } from '../../../../constants/pay.constants';
import { calc_products_v2 } from '../../billings/product_builder';

import './PayActions.scss';

const PayActions = () => {
  const { area } = useSelector((state) => state.apps);
  const { account } = useSelector((state) => state.auth);
  const { cart } = useSelector((state) => state.kiosk);
  const { customer } = useSelector((state) => state.billings);
  const [action, set_action] = useState(ACTIONS.QUANTITY);
  const [current_customer, set_current_customer] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  let timeout = null;
  let text = null;

  const on_handle_select_client = () => {
    dispatch(actions.kiosk.open_client_dialog({ is_open: true }));
  };

  const pessKeyAction = (value) => {
    if (text == null && value === '.') {
      return;
    }
    text = text ? text + String(value) : String(value);
    // eslint-disable-next-line
    clearTimeout(timeout);
    // eslint-disable-next-line
    timeout = setTimeout(() => {
      const amount = parseFloat(text);
      text = null;
      dispatch(actions.kiosk.pay_action({ action: action.key, amount }));
    }, 500);
  };

  const on_pay = useCallback((item) => {
    history.push(`/market/sales/booking/${item._id}/checkout`);
  }, [history]);

  useEffect(() => {
    set_current_customer(customer);
  }, [customer]);

  const create_booking = useCallback(() => {
    const products = calc_products_v2(cart);

    const new_item = {
      sales_type: 'kiosk',
      start: moment().startOf('day'),
      end: moment().endOf('day'),
      area_id: area._id,
      room_id: null,
      customer: current_customer || null,
      room_ids: [],
      visitors: [],
      account_id: account._id,
      account,
      otros: {},
      rooms: [],
      products: [...products],
      retains: [],
      // active: true,
      // completed: null,

      active: false,
      completed: new Date(),

      coin_type: 'cash',
      payment_type: 'receipt',
      order_type: null,
      index: 0,
    };

    const config = StorageService.load('checkout-config');
    if (config) {
      new_item.coin_type = config.coin_type.key;
      new_item.payment_type = config.payment_type.key;
      new_item.order_type = config.order_type.key;
    }

    loader.resolve()
    .then(loader.start)
    .then(() => api.bookings.create(new_item))
    .then(({ data }) => {
      on_pay(data);
    })
    .then(loader.stop)
    .catch(loader.error)
    .catch(toast.api_danger);
  }, [account, area, current_customer, cart, on_pay]);

  const handle_action = (event) => {
    switch (event.key) {
    case ACTIONS.CUSTOMER.key:
      on_handle_select_client();
      break;
    case ACTIONS.PAYMENT.key:
      create_booking();
      // history.push('/sales/checkout');
      break;
    case ACTIONS.ONE.key:
      pessKeyAction(1);
      break;
    case ACTIONS.TWO.key:
      pessKeyAction(2);
      break;
    case ACTIONS.THREE.key:
      pessKeyAction(3);
      break;
    case ACTIONS.FOUR.key:
      pessKeyAction(4);
      break;
    case ACTIONS.FIVE.key:
      pessKeyAction(5);
      break;
    case ACTIONS.SIX.key:
      pessKeyAction(6);
      break;
    case ACTIONS.SEVEN.key:
      pessKeyAction(7);
      break;
    case ACTIONS.EIGHT.key:
      pessKeyAction(8);
      break;
    case ACTIONS.NINE.key:
      pessKeyAction(9);
      break;
    case ACTIONS.ZERO.key:
      pessKeyAction(0);
      break;
    case ACTIONS.QUANTITY.key:
      set_action(ACTIONS.QUANTITY);
      break;
    case ACTIONS.DISCOUNT.key:
      console.info('unimplemented');
      break;
    case ACTIONS.PRICE.key:
      set_action(ACTIONS.PRICE);
      break;
    case ACTIONS.CANCEL.key:
      break;
    case ACTIONS.DOT.key:
      pessKeyAction('.');
      break;
    case ACTIONS.PLUSLESS.key:
      console.info('unimplemented');
      break;
    default:
      console.error(`case ${action} unimplemented`);
      break;
    }
  };

  return (
    <div className="controls-actions">
      <div className="pay-actions">
        <div className="action-customer">
          <SalesButton item={ACTIONS.CUSTOMER} onClick={handle_action} />
        </div>
        <div className="action-payment">
          <SalesButton item={ACTIONS.PAYMENT} onClick={handle_action} />
        </div>
      </div>
      <div className="numbers-actions-vertical">
        <div className="actions">
          <div className="numbers-actions-horizontal">
            <div className="actions">
              <SalesButton item={ACTIONS.SEVEN} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.EIGHT} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.NINE} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.QUANTITY} current={action} onClick={handle_action} />
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="numbers-actions-horizontal">
            <div className="actions">
              <SalesButton item={ACTIONS.FOUR} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.FIVE} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.SIX} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.DISCOUNT} current={action} onClick={handle_action} />
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="numbers-actions-horizontal">
            <div className="actions">
              <SalesButton item={ACTIONS.ONE} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.TWO} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.THREE} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.PRICE} current={action} onClick={handle_action} />
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="numbers-actions-horizontal">
            <div className="actions">
              <SalesButton item={ACTIONS.PLUSLESS} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.ZERO} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.DOT} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.CANCEL} onClick={handle_action} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PayActions };
