import React from 'react';

import { VContainer } from 'components';

// #5d1744
const Search = () => (
  <VContainer>
    Buscador
  </VContainer>
);

export { Search };
