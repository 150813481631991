import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormLabels } from 'components/forms/v1';
import { ContentTitle } from 'components';
import { Image } from 'components/images/v3';
import { AREA_DETAIL_TITLE } from '../areas.constants';

const HomeArea = ({ area }) => {
  const [item] = useState(area);

  return (
    <FormLabels>
      <ContentTitle config={AREA_DETAIL_TITLE} />
      <FormLabels.Field>
        <FormLabels.Label value="Nombre" />
        <FormLabels.Input value={item.name} />
      </FormLabels.Field>
      <FormLabels.Field>
        <FormLabels.Label value="Descripción" />
        <FormLabels.Input value={item.description} />
      </FormLabels.Field>
      <Image image={item.image_id} direction={Image.H} height="150px" quality={1080} />
    </FormLabels>
  );
};

HomeArea.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { HomeArea };
