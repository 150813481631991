import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import image from '../../../../assets/images/product.jpeg';
import { HToolbarButton } from '../../../container/HToolbarContainer';

import './Chip.scss';

const Chip = ({ item, current, onHandleClick }) => {
  const onClick = (e) => {
    e.stopPropagation();
    onHandleClick(item);
  };

  return (
    <HToolbarButton>
      <div className={classnames('chip', { 'on-select': current && current._id === item._id })} onClick={onClick}>
        <div>
          <img src={item.image || image} alt={item.name} />
        </div>
        <div>
          <h5>{item.name || item.email}</h5>
        </div>
      </div>
    </HToolbarButton>
  );
};

Chip.propTypes = {
  item: PropTypes.any.isRequired,
  current: PropTypes.any.isRequired,
  onHandleClick: PropTypes.func.isRequired,
};

export { Chip };
