import { types } from 'store/actions/billings/billings.actions';

const initialState = {
  customer: null,
};

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
  case types.STORE_CUSTOMER:
    return {
      ...state,
      customer: data,
    };

  default:
    return state;
  }
};

export default reducer;
