import React from 'react';
import { useSelector } from 'react-redux';

import { Flex } from 'components/grids/v4';
import { StoreProductList } from './StoreProductList';
import { StoreProductToolbar } from './StoreProductToolbar';

const StoreProduct = () => {
  const { area } = useSelector((state) => state.apps);

  return (
    <Flex direction={Flex.H}>
      {area && (
        <StoreProductToolbar area={area}>
          <StoreProductList />
        </StoreProductToolbar>
      )}
    </Flex>
  );
};

export { StoreProduct };
