import { GENERIC } from 'generics/constants';

export const URI = {
  get: '/v1/product-stocks',
  get_by_id: '/v1/product-stocks/{_id}',
  post: '/v1/product-stocks',
  put: '/v1/product-stocks/{_id}',
  remove: '/v1/product-stocks/{_id}',
};
export const ROUTE = {
  list: `${GENERIC.APP_BASE_URL}/product-stocks`,
};
export const CONTEXT = 'Invetario';
export const TITLE = 'Inventario de un producto';
export const ITEM_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'area de creacion y edicion de products',
};
export const ITEMS_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'lista de products',
};
export const BUTTON_LABELS = {
  NEW: 'Crear inventario',
  REMOVE: 'Eliminar inventario',
  CREATE: 'Crear inventario',
  UPDATE: 'Actualizar inventario',
};
