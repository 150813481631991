import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { api, toast } from 'generics/services/globals/v3';
import { ContentTitle } from 'components/titles/v2';
import { Flex } from 'components/grids/v2';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { RemoveConfirmation } from 'components/generics/v1';
import { UImage } from 'components/images/v3';
import { IButton } from 'components/buttons/v2';
import { ITEM_HEADER, BUTTON_LABELS } from './constants';

const GItem = ({ route, uri, area }) => {
  const { _id } = useParams();
  const history = useHistory();
  const [is_new] = useState(_id === undefined);
  const {
    item,
    set_item,
    on_string_change,
    on_image_change,
  } = useFormReducer({ area_id: area._id, maintenance_id: null, housekeeping_id: null });

  const create = () => {
    api.post(uri.post, null, item)
    .then(() => {
      history.push(route.list);
    })
    .catch(toast.api_danger);
  };

  const update = () => {
    api.put(uri.put, { _id }, item)
    .then(() => {
      history.push(route.list);
    })
    .catch(toast.api_danger);
  };

  const on_submit = (e) => {
    e.preventDefault();
    if (is_new) {
      create();
    } else {
      update();
    }
  };

  const on_remove_callback = useCallback(() => {
    api.remove(uri.remove, { _id }, item)
    .then(() => {
      history.push(route.list);
    })
    .catch(toast.api_danger);
  }, [uri, _id, history, route, item]);

  useEffect(() => {
    if (is_new) return;

    api.get(uri.get_by_id, { _id }, null)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [is_new, _id, uri, area, set_item]);

  const render_buttons = () => {
    if (is_new) {
      return <IButton label={BUTTON_LABELS.CREATE} icon="checkmark" submit />;
    }

    return (
      <>
        <RemoveConfirmation label={BUTTON_LABELS.REMOVE} on_click={on_remove_callback} />
        <IButton label={BUTTON_LABELS.UPDATE} icon="checkmark" submit />
      </>
    );
  };

  return (
    <>
      <ContentTitle config={ITEM_HEADER} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Nombre" value={item.name} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripción" value={item.description} onChange={on_string_change} />
        </Form.Field>
        <UImage image={item.image_id} height="150px" quality={1080} on_callback={on_image_change} />
        <Flex.End>
          {render_buttons()}
        </Flex.End>
      </Form>
    </>
  );
};

GItem.propTypes = {
  route: PropTypes.any.isRequired,
  uri: PropTypes.shape({
    get: PropTypes.string.isRequired,
    get_by_id: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    put: PropTypes.string.isRequired,
    remove: PropTypes.string.isRequired,
  }).isRequired,
  area: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export { GItem };
